import { useMemo, useRef } from 'react';
import { url } from '@/helpers/general';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ForsetiLogo } from '@/helpers/svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetOrganisations } from '@/Service/Api/ApiHooks/Organisation/useGetOrganisations';
import { useAuthStore } from '@/Stores/AuthStore';
import { MenuItem } from "primereact/menuitem";
import { checkPermission } from "@/Util/permissionChecks";
import BaseDropdown from "@/components/Core/Form/BaseDropdown";
import { useOrgId } from "@/Hooks/useOrgId";
import { useClearUserCache } from "@/Service/Api/ApiHooks/AuthUser/useClearUserCache";
import { clearStorage } from "@/helpers/storage";
import { Menu } from "primereact/menu";
import UserAvatar from "@/components/Core/UserAvatar";
import { OverlayPanel } from "primereact/overlaypanel";
import styled, { createGlobalStyle } from "styled-components";
import { useGetNotifications } from "@/Service/Api/ApiHooks/Notification/useGetNotifications";
import { Divider } from "primereact/divider";
import { formatToUKDate } from "@/Util/formatToUKDate";
import { Button } from "primereact/button";
import { useImpersonatedOrgId } from "@/Hooks/useImpersonatedOrgId";
import { Message } from "primereact/message";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { useQueryClient } from "@tanstack/react-query";
import { useGetNotificationsUnreadCount } from "@/Service/Api/ApiHooks/Notification/useGetNotificationsUnreadCount";
import { Badge } from "primereact/badge";
import { useMarkNotificationAsRead } from "@/Service/Api/ApiHooks/Notification/useMarkNotificationAsRead";

const StyledWrap = styled.div`
    .notification-icon {
        color: var(--gray-500);
        font-size: 1.5rem;
        cursor: pointer;
    }

    .notification-badge {
        min-width: 1.3rem;
        height: 1.3rem;
        line-height: 1.3rem;
    }
`;

const NotificationsStyle = createGlobalStyle`
    .notifications {
        &__list {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        &__item {
            margin-top: 0.5rem;
        }
    }
`;

const Header = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { logout: logoutAuth0 } = useAuth0();
    const userProfileMenuRef = useRef<Menu>(null);
    const notificationOverlayRef = useRef<OverlayPanel>(null);
    const authUser = useAuthStore((state) => state.authUser);
    const impersonatedOrgId = useImpersonatedOrgId();

    const setImpersonatedOrgId = useAuthStore((state) => state.setImpersonatedOrgId);
    const orgId = useOrgId();

    const logoutApiMutation = useClearUserCache();

    const {
        data: organisations
    } = useGetOrganisations();

    const organizationOptions = useMemo(() => {
        if (!organisations) {
            return [];
        }
        return organisations.data.map((org) => {
            return {
                value: org.id,
                label: org.name
            };
        });
    }, [organisations]);

    const userMenuItems = useMemo<MenuItem[]>(() => {
        const menuItems: MenuItem[] = [
            {
                className: 'content-item mb-2',
                template: () => {
                    return (
                        <div
                            className="user-info flex align-items-center"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <UserAvatar
                                user={authUser}
                            />

                            <div>
                                <div className="user-name">{authUser?.display_name || ''}</div>
                                <div className="user-email">{authUser?.email || ''}</div>
                            </div>
                        </div>
                    );
                }
            },
            {
                separator: true
            },
        ];

        if (checkPermission(authUser?.permissions, 'impersonate:any_org')) {
            menuItems.push(
                {
                    className: 'mt-2 border-round',
                    template: () => {
                        return (
                            <BaseDropdown
                                placeholder="Organisation"
                                pt={{ dropdown: { className: 'w-full' } }}
                                options={organizationOptions}
                                optionLabel="label"
                                optionValue="value"
                                value={orgId}
                                onClick={e => e.stopPropagation()}
                                onChange={(e) => {
                                    if (authUser?.organisation_id === e.value) {
                                        setImpersonatedOrgId(undefined);
                                    } else {
                                        setImpersonatedOrgId(e.value);
                                    }
                                    void Promise.all([
                                        queryClient.invalidateQueries({
                                            queryKey: QueryKeys.complaints._def
                                        })
                                    ]);
                                    navigate(url('inbox', { orgId: e.value }));
                                }}
                            />
                        );
                    }
                }
            );
        }
        menuItems.push(
            {
                className: 'mt-2',
                label: 'My profile',
                command: () => {
                    navigate(url('my-profile', { orgId: orgId }));
                }
            }
        );
        menuItems.push(
            {
                className: 'mt-2',
                label: 'Logout',
                command: () => {
                    logoutApiMutation.mutate({
                        ClearAuthUserCacheRequest: {
                            auth0_user_id: authUser?.auth0_id
                        }
                    }, {
                        onSuccess: () => {
                            clearStorage();
                            logoutAuth0({ logoutParams: { returnTo: window.location.origin } });
                        }
                    });
                }
            }
        );
        return menuItems;
    }, [authUser, organizationOptions, orgId, setImpersonatedOrgId, navigate, logoutApiMutation, logoutAuth0]);

    const {
        data: notificationsData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useGetNotifications();

    const { data: notificationsUnreadCount } = useGetNotificationsUnreadCount();

    const { mutate } = useMarkNotificationAsRead();

    const markNotificationAsRead = (notificationId: string) => {
        mutate(
            {
                notification_id: notificationId
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ ...QueryKeys.notifications.list() });
                    queryClient.invalidateQueries({ ...QueryKeys.notifications.unreadCount });
                }
            }
        );
    };

    return (
        <StyledWrap>
            <div className="main-header h-full">
                <div className="flex gap-4 justify-content-between align-items-center">
                    <Link
                        to={url('inbox', { orgId: orgId })}
                        className="site-logo uppercase-dark-text p-link flex"
                    >
                        <ForsetiLogo/>
                    </Link>

                    {
                        impersonatedOrgId
                        && <div>
                            <Message text="Impersonated Organisation usage detected. Please don't make any changes to the data!"/>
                        </div>
                    }
                    <div className="flex align-items-center gap-3">
                        <NotificationsStyle/>
                        <div
                            onClick={(e) => notificationOverlayRef.current?.toggle(e, undefined)}
                        >
                            <i className="notification-icon pi pi-bell p-overlay-badge">
                                {
                                    notificationsUnreadCount
                                    && <Badge value={notificationsUnreadCount} className="notification-badge"></Badge>
                                }
                            </i>
                        </div>
                        <OverlayPanel ref={notificationOverlayRef}>
                            <div className="notifications">
                                <h4 className="m-0">Notifications</h4>
                                <Divider/>
                                <ul className="notifications__list">
                                    {
                                        notificationsData
                                        && notificationsData.pages.map(page => {
                                            return page.data.map((notification) => {
                                                return <li
                                                    key={notification.id}
                                                    className="notifications__item"
                                                >
                                                    <div className="flex gap-3 justify-content-between">
                                                        <div>
                                                            <p className="m-0">{notification.body}</p>
                                                            {
                                                                notification.complaint_id && <div>
                                                                    <NavLink
                                                                        to={url('complaints.view', {
                                                                            orgId,
                                                                            complaintId: notification.complaint_id
                                                                        })}
                                                                    >
                                                                        Show Complaint
                                                                    </NavLink>
                                                                </div>
                                                            }
                                                            <small>{formatToUKDate(notification.created_at)}</small>
                                                        </div>
                                                        {
                                                            !notification.read_at
                                                            && <div>
                                                                <Button onClick={() => markNotificationAsRead(notification.id)}>Read</Button>
                                                            </div>
                                                        }
                                                    </div>
                                                </li>;
                                            });
                                        })
                                    }
                                </ul>
                                {
                                    hasNextPage && <Button
                                        label="Load more"
                                        loading={isFetchingNextPage}
                                        onClick={async () => {
                                            await fetchNextPage();
                                        }}
                                    />
                                }
                            </div>
                        </OverlayPanel>
                        <div
                            className="user-info-wrap flex align-items-center mr-3 z-50"
                            onMouseEnter={(e) => userProfileMenuRef.current?.show(e)}
                            onMouseLeave={(e) => userProfileMenuRef.current?.hide(e)}
                        >
                            <UserAvatar
                                user={authUser}
                            />

                            <div className="user-info">
                                <div>{authUser?.display_name}</div>
                            </div>

                            <Menu
                                className="user-menu w-auto p-3"
                                model={userMenuItems}
                                popup
                                ref={userProfileMenuRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </StyledWrap>
    );
};

export default Header;
